import { useEffect } from "react";

// Declare `fbq` as part of the global `window` object, but it might be `undefined` initially.
declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
    _fbq?: (...args: any[]) => void;
  }
}

const FacebookPixel = (): null => {
  useEffect(() => {
    (function (f: Window, b: Document, e: string, v: string, n?: any, t?: HTMLScriptElement, s?: HTMLScriptElement) {
      // Check if `fbq` is already defined
      if (typeof f.fbq !== "undefined") return;
      n = f.fbq = function () {
        (n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments));
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e) as HTMLScriptElement;
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0] as HTMLScriptElement;

      // Add null check before accessing `parentNode`
      if (s && s.parentNode) {
        s.parentNode.insertBefore(t, s);
      }
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    // Check if `fbq` is defined before invoking it
    if (typeof window.fbq !== "undefined") {
      window.fbq("init", "YOUR_PIXEL_ID"); // Replace with your actual Pixel ID
      window.fbq("track", "PageView");
    }
  }, []);

  return null;
};

export default FacebookPixel;
